import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule } from '@util/components/icon/icon.module';
import { ColorThemeModule } from '@util/components/shared/color-theme/color-theme.module';
import { KurzLegalDocumentComponent } from './kurz-legal-document.component';
import { I18nModule, TranslatePipe } from '@spartacus/core';
import { KurzDatePipe } from 'src/app/custom/pipes/kurz-unit/kurz-date.pipe';
import { KurzUnitPipesModule } from 'src/app/custom/pipes/kurz-unit/kurz-unit-pipes.module';
import { HtmlDeserializerModule } from '@util/components/html-deserializer/html-deserializer.module';

@NgModule({
  declarations: [
    KurzLegalDocumentComponent
  ],
  imports: [
    CommonModule,
    I18nModule,
    IconModule,
    ColorThemeModule,
    KurzUnitPipesModule,
    HtmlDeserializerModule
  ],
  exports: [
    KurzLegalDocumentComponent
  ],
  providers: [
    TranslatePipe
  ]
})
export class SharedKurzLegalDocumentModule { }
